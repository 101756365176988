import React from 'react'
import { Box, Container, CssBaseline } from '@material-ui/core'
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles'

import themeConfig from '../../config/theme'
import SEO from '../atoms/seo'

const theme = responsiveFontSizes(createMuiTheme(themeConfig))

export default ({ children, data }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SEO />
    <Container>
      <Box component="main">{children}</Box>
    </Container>
  </ThemeProvider>
)
